//@ts-nocheck
import { useState } from "react";
import { Image } from "../../assets/images";

export default function DragSpace() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    role: "Performer",
    message: "",
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const scriptURL = "https://script.google.com/macros/s/AKfycbwSZzaDkBgHhLJT_YQuO6bS42yfwWrOme5qYMYUAEgwV8W77FF0R9xPiuKfCNcjsmc/exec";

      const response = await fetch(scriptURL, {
        method: "POST",
        mode: 'no-cors',
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json"
        }
      });

      alert("Form submitted successfully!");
      setFormData({ name: "", phone: "", email: "", role: "Performer", message: "" });

    } catch (error) {
      alert("An error occurred. Please try again later.");
      console.error("Submission Error:", error);
    }

    setLoading(false);
  };

  const handleRoleSelect = (role) => {
    setFormData((prevState) => ({
      ...prevState,
      role,
    }));
    setDropdownOpen(false);
  };

  return (
    <div className="min-h-screen overflow-hidden w-full bg-[#030201] flex flex-col items-center justify-center p-4 md:p-8 relative">
      <div className="absolute top-[200px] left-1/2 -translate-x-1/2 overflow-visible opacity-[0.07] z-10">
        <img src={Image.Logo} className="h-full w-full scale-150" />
      </div>
      <div className="absolute top-[200px] left-1/2 -translate-x-1/2 overflow-visible z-0 opacity-75">
        <img src={Image.Glow} className="h-full w-full scale-150" />
      </div>

      <div className="max-w-3xl w-full z-20">
        <div className="text-center mb-6 flex flex-col items-center">
          <img src={Image.Logo} className="px-5 md:px-0 md:h-[140px]" />
          <p className="text-white mt-6">Patience Is A Virtue...</p>
          <h3 className="text-[#ff00a2] text-4xl md:text-6xl font-bold mt-4">It's Coming, Honey!</h3>
        </div>

        <form onSubmit={handleSubmit} className="mt-8 space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="name" className="block text-white mb-1">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Type name..."
                value={formData.name}
                onChange={handleChange}
                className="w-full p-2 rounded"
                required
              />
            </div>

            <div>
              <label htmlFor="phone" className="block text-white mb-1">
                Phone
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder="Type number..."
                value={formData.phone}
                onChange={handleChange}
                className="w-full p-2 rounded"
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="email" className="block text-white mb-1">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="abc@com..."
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 rounded"
                required
              />
            </div>

            <div className="relative">
              <label htmlFor="role" className="block text-white mb-1">
                Are you a...
              </label>
              <div className="relative">
                <button
                  type="button"
                  className="w-full p-2 rounded bg-white text-left flex justify-between items-center"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  <span>{formData.role}</span>
                  <span>▼</span>
                </button>

                {dropdownOpen && (
                  <div className="absolute z-10 w-full mt-1 bg-white rounded shadow-lg">
                    {["Venue", "Performer", "Fan", "Advertiser", "Other"].map((role) => (
                      <div
                        key={role}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleRoleSelect(role)}
                      >
                        {role}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            <label htmlFor="message" className="block text-white mb-1">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              placeholder="Type your message here..."
              value={formData.message}
              onChange={handleChange}
              className="w-full p-2 rounded h-32"
              required
            />
          </div>

          <div className="flex justify-center mt-6">
            <button
              type="submit"
              className="bg-[#ff00a2] text-white py-2 px-8 rounded hover:bg-pink-600 transition-colors w-full"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
