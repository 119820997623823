
import { createBrowserRouter, Outlet } from "react-router-dom";
import MainLandingPage from "../pages/Landing/MainLandingPage";
import SiteLayout from "../layouts/SiteLayout";

const Router = () => {

   return createBrowserRouter([
      {
         path: "/",
         element: <Outlet />,
         children: [
            {
               index: true,
               element: <MainLandingPage />,
            },
            

         ],
      }
   ]);
};

export default Router